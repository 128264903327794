import React from "react";
import {CustomCard} from "../CustomCard";
import ContactBar from "../../components/ContactBar/ContactBar";

function Home() {
    return (
        <div className="about">
            <img className="my-pic" src="./ynw.jpg" alt="Yan Naing Win"></img>
            <CustomCard>
                <h1>About</h1>
                <p>Hi! I am Yan Naing. Most call me Yan, some call me Yan Naing (pronounced Yan-Nai), either's
                    fine. I am now into my last semester of undergraduate at the University of British Columbia in
                    Vancouver, Canada studying Computer Science.</p>
                <p>I am originally from a city called Yangon a.k.a Rangoon in Myanmar(Burma). You may well have heard of
                    Crab Rangoon dish but it's only named after the city; not a local dish. Myanmar's a beautiful hidden gem
                    in Southeast Asia, maybe you should consider visiting us when things have settled down in the country 😉.
                </p>
                <figure>
                    <img src="./yangon.jpg" alt="Yangon"></img>
                    <figcaption>
                        Downtown Yangon - Photo by Alexander Schimmeck
                    </figcaption>
                </figure>
                <p>
                    I see myself as a developer. Most of the things I have built come from my own experiences, and that always makes me believe
                    in my projects. When I spend a lot of time around something, it naturally comes to me to find some gaps that I could fill. The more I dive into it, I more
                    I realize about the reality, sparking ideas to create software solutions. Then, I try to make it happen. Check out my projects
                    <a href="/projects" style={{color: 'white'}}>here</a>.
                </p>
                <p>
                    I am a big fan of football/soccer. I played as a keeper for my school team for about 7 years.
                    I am more of a fan of my country's football team than any other.
                </p>
                <figure>
                    <img src="./footy.JPG" alt="Football Match"></img>
                    <figcaption>
                        After our 9-0 win on an away day in 2015
                    </figcaption>
                </figure>
            </CustomCard>
            <ContactBar/>
        </div>
        // <div className="home">
        //     <img src="./WorkAtVPL.jpg" alt="Working at the library"></img>
        //     <CustomCard>
        //         <h2>Hi! I am Yan Naing!</h2>
        //         <p>April 27th 2024</p>
        //         <p>The day after my last exam of third year. I packed my
        //             bag and headed to Vancouver Public Library in downtown. There, I went to the 6th floor, sat at the
        //             table in the corner, and started building this website while listening to Laufey's music. Jazz is ❤️.
        //         </p>
        //         <ContactBar/>
        //     </CustomCard>
        // </div>
    )
}

export default Home;