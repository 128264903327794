import React from "react";
import {CustomCard} from "../CustomCard";
import ContactBar from "../../components/ContactBar/ContactBar";
import {List} from "@mui/material";
import {SkillCard, SkillSection} from "./SkillCard";

function Experience() {
    return (
        <div className="experience">
            <h1>Skills & Experience</h1>
            <h3><u>Technical Skills</u></h3>
            <SkillSection>
                <SkillCard title={"Programming"} skills={["Java", "JavaScript", "TypeScript", "Scala"]}/>
                <SkillCard title={"Framework"} skills={["React", "Next.js", "Node.js", "Express.js", "Unity"]}/>
                <SkillCard title={"Databases"} skills={["PostgreSQL", "MongoDB"]}/>
                <SkillCard title={"Tools"} skills={["Git", "Docker", "Kubernetes", "Apache NiFi", "Splunk", "JIRA"]}/>
            </SkillSection>
            <CustomCard>
                <p>
                    Other than these, I have worked with various other technologies. In terms of programming languages,
                    I have
                    used C, C++ and Python in my coursework, as well as C# during my time at the game development club.
                    During my co-op, I used to work with Docker and Kubernetes to push my images to the artifactory.
                </p>
                <p>
                    I have taken CPSC 322 - Introduction to AI and CPSC 330 - Applied Machine Learning at UBC. I am
                    starting have
                    a good grasp of the concepts of AI and Machine Learning. I plan to take CPSC 340 - Machine Learning
                    and Data Mining
                    and CPSC 422 - Intelligent Systems to skill up in these fields. I think it's important to know these
                    now that AI is becoming more and more prevalent.
                </p>
                <h3><u>Experience</u></h3>
                <img src="./coop.jpg" alt="My co-op team"></img>
                <p>
                    I started my co-op in September 2022. I worked at a company called
                    <a href="https://www.macrohealth.com/" style={{color: 'white', textDecoration: 'none'}}> <u>MacroHealth</u> </a>
                    as a Software Engineer Intern for a year. In the first eight months, I worked in the backend team, tackling problems around the organization's platform that handles customers' data.
                    I worked on a variety of issues, a few of which were: fixing a rollback mechanism, introducing the handling of sad paths during data processing, and implementing features
                    around the platform's logs combined with Splunk. I also worked together with the SDET team to write unit/API tests for the features I implemented.
                    During my time in this team, fixing API bugs was something I found quite joyful. It was always fun to find why it was returning other status code when it should have returned 200, or
                    sometimes returning 200 with nothing in them. They really were fun to debug and solve.
                </p>
                <p>
                    In the last four months, I worked in a new team where I got to work on a production level project for real life customers.
                    Some of the customers were big names in the industry. In this team, I got to assemble features, write logics based on customer configuration, and tested them rigorously through e2e tests.
                    Then, my time was up after these four months, and I went back to university. I do miss the place and the people I worked with.
                </p>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default Experience;