import React from "react";
import {CustomCard} from "../CustomCard";
import ContactBar from "../../components/ContactBar/ContactBar";

function About() {
    return (
        <div className="about">
            <img className="my-pic" src="./ynw.jpg" alt="Yan Naing Win"></img>
            <CustomCard>
                <h1>About</h1>
                <p>Hi! I am Yan Nain. Most call me Yan, some call me Yan Naing (sounds like 9), either's
                fine. I am now into my last year of undergraduate at the University of British Columbia in
                Vancouver, Canada studying Computer Science.</p>
                <p>I am originally from a city called Yangon a.k.a Rangoon in Myanmar(Burma). You may well have heard of
                    Crab Rangoon dish but it's only named after the city. Myanmar's a beautiful hidden gem
                    in Southeast Asia, maybe you should consider visiting us when things have settled down in the country 😉.
                </p>
                <figure>
                    <img src="./yangon.jpg" alt="Yangon"></img>
                    <figcaption>
                        Downtown Yangon - Photo by Alexander Schimmeck
                    </figcaption>
                </figure>
                <p>
                    I like to call myself as a developer. Most of the things I have built come from my own experiences, and that always makes me believe
                    in my projects. When I spend a lot of time around something, it naturally comes to me to find some gaps that I could fill. The more I dive into it, I more
                    I realize about the reality, sparking ideas to create software solutions. Then, I try to make it happen.
                </p>
                <p>
                    I am a big fan of football/soccer. I played as a keeper for my school team for about 7 years.
                    I am more of a fan of my country's football team than any other.
                </p>
                <figure>
                    <img src="./footy.JPG" alt="Football Match"></img>
                    <figcaption>
                        After our 9-0 win on an away day in 2015
                    </figcaption>
                </figure>
            </CustomCard>
            <ContactBar/>
        </div>
    )
}

export default About;