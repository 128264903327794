import React from "react";
import {DesktopNavBarStyled, DesktopNavButtonStyled, DesktopToolBarStyled} from "./DesktopNavBarStyling";
import {Link} from "react-router-dom";

function DesktopNavBar() {
    return (
        <DesktopNavBarStyled>
            <DesktopToolBarStyled>
                <Link to="/">
                    <DesktopNavButtonStyled>Home</DesktopNavButtonStyled>
                </Link>
                {/*<Link to="/about">*/}
                {/*    <DesktopNavButtonStyled>About</DesktopNavButtonStyled>*/}
                {/*</Link>*/}
                <Link to="/experience">
                    <DesktopNavButtonStyled>Experience</DesktopNavButtonStyled>
                </Link>
                <Link to="/projects">
                    <DesktopNavButtonStyled>Projects</DesktopNavButtonStyled>
                </Link>
                <Link to="/university">
                    <DesktopNavButtonStyled>University</DesktopNavButtonStyled>
                </Link>
                <Link to="/trivia">
                    <DesktopNavButtonStyled>Trivia</DesktopNavButtonStyled>
                </Link>
                <Link to="/blog">
                    <DesktopNavButtonStyled>Blog</DesktopNavButtonStyled>
                </Link>
            </DesktopToolBarStyled>
        </DesktopNavBarStyled>
    )
}

export default DesktopNavBar;